import React, { useEffect, useState, useCallback, useRef } from "react";
import { GoogleMap, useJsApiLoader, Polyline } from "@react-google-maps/api";
import Typography from "@mui/material/Typography";

const center = {
  lat: 46.5193587,
  lng: 12.0051628,
};

function MapModule({ polygon, filterOption }) {
  const [mapKey, setMapKey] = useState(Date.now());
  const [map, setMap] = useState(null);
  const needRefresh = useRef(true);

  const polylineOptions = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    paths: polygon.polyline,
    zIndex: 1,
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_googleMapsApiKey,
    id: "google-map-script",
    // libraries,
  });

  // Remove bug not loading map after filter option
  useEffect(() => {
    needRefresh.current = true;
  }, [filterOption]);

  useEffect(() => {
    if (!map) return;
    zoomToObject();
  }, [polygon.polyline]);

  // Remove bug not loading map after filter option
  useEffect(() => {
    if (needRefresh.current == true) {
      setMapKey(Date.now());
      // console.log("Refreshing working");
      needRefresh.current = false;
    }
  }, [polygon]);

  const zoomToObject = useCallback(() => {
    const bounds = new window.google.maps.LatLngBounds();
    polygon.polyline.forEach((point) => bounds.extend(point));
    map.fitBounds(bounds);
  }, [polygon.polyline]);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  return isLoaded ? (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "row",
        backgroundColor: "black",
      }}
    >
      {" "}
      <Typography variant="h5" color="white" sx={{ mt: 1 }}>
        {/* {needRefresh.current.toString()} */}
        {/* mapId = {mapId}, cardId = {cardId} */}
      </Typography>
      <GoogleMap
        key={mapKey}
        mapContainerClassName="map"
        onLoad={onLoad}
        onUnmount={onUnmount}
        center={center}
        zoom={5}
      >
        <Polyline path={polygon.polyline} options={polylineOptions} />
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
}

export default React.memo(MapModule);
