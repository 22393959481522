import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  GoogleApiWrapper,
} from "@react-google-maps/api";
import api from "../api.js";
import { Polyline } from "@react-google-maps/api";
import Typography from "@mui/material/Typography";
import renderArrows from "./renderArrows"; // Adjust the path as needed
import { polylineOptions, getHitAreaOptions } from "./polylinesOptions.js";

const center = {
  lat: 46.5193587,
  lng: 12.0051628,
};

function MapModuleForMaps(props) {
  const {
    filterOption,
    polygon,
    cardItemsList,
    displayOption,
    setTripId,
    setMapId,
    showManyTracks,
  } = props;
  const [mapKey, setMapKey] = useState(Date.now());
  const needRefresh = useRef(true);
  const [zoomLevel, setZoomLevel] = useState(5);

  const [map, setMap] = React.useState(null);
  //   const [cardItemsList, setCardImetsList] = useState([]);

  const polylinesArray = cardItemsList.map(
    (item) => item.attributes.polygon.polyline
  );

  const API_KEY = process.env.REACT_APP_googleMapsApiKey;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
  });

  // Remove bug not loading map after filter option
  useEffect(() => {
    needRefresh.current = true;
    console.log("needRefresh set to true ...", needRefresh);
  }, [filterOption]);

  useEffect(() => {
    // console.log("useEffect: cardId= ", cardId);
    // console.log("useEffect: mapId= ", mapId);
    // console.log("cardItemsList = ", cardItemsList);
    // console.log("polylinesArray = ", polylinesArray);

    if (!map) return;
    zoomToObject(polygon.polyline);
    // console.log("zoom to poly =", polygon.polyline);
  }, [polygon.polyline]);

  // Remove bug not loading map after filter option
  useEffect(() => {
    if (needRefresh.current == true) {
      setMapKey(Date.now());
      console.log("Refreshing working");
      needRefresh.current = false;
    } else console.log("Refresh forbidden");
  }, [polygon.polyline, displayOption]);

  const zoomToObject = useCallback(() => {
    const bounds = new window.google.maps.LatLngBounds();
    polygon.polyline.forEach((point) => bounds.extend(point));
    map.fitBounds(bounds);
  }, [polygon.polyline]);

  const onLoad = useCallback((map) => {
    setMap(map);
    map.addListener("zoom_changed", () => {
      setZoomLevel(map.getZoom());
    });
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const findCardId = (index) => {
    const card = cardItemsList.find((_, idx) => idx === index);
    return card ? card.id : null;
  };

  const onPolylineClicked = (index) => {
    // console.log(findCardId(index));
    setMapId(findCardId(index));
    setTripId(findCardId(index));
  };

  const path = polygon.polyline;

  return isLoaded ? (
    <div
      style={{
        display: "flex",
        flexGrow: "1",
        backgroundColor: "blue",
        flexDirection: "row",
      }}
    >
      <GoogleMap
        // mapContainerStyle={containerStyle}
        key={mapKey}
        mapContainerClassName="map"
        center={center}
        zoom={5}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {showManyTracks && (
          <>
            {polylinesArray.map((path, index) => (
              <div key={index}>
                <Polyline
                  key={index * 1000 + 1}
                  path={path}
                  options={polylineOptions}
                  onClick={() => onPolylineClicked(index)}
                />

                <Polyline
                  key={index}
                  path={path}
                  options={getHitAreaOptions(polygon)}
                  onClick={() => onPolylineClicked(index)}
                />
              </div>
            ))}
          </>
        )}

        <Polyline
          path={polygon.polyline}
          options={{
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 3,
          }}
        />
        {renderArrows(path, zoomLevel)}
        <></>
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
}

export default React.memo(MapModuleForMaps);
