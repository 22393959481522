import CardModuleMulti from "../components/CardModuleMulti.js";
import { useState, useEffect, useRef } from "react";
import api from "../api.js";
import MapModuleForMaps from "../components/MapModuleForMaps.js";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Typography from "@mui/material/Typography";
import Buttons from "../components/Buttons.js";
import CustomizedDialogs from "../components/DownloadAlert.js";
import CustomizedDialogsInfo from "../components/InfoAlert.js";
import CustomizedDialogsFilter from "../components/FilterAlert.js";

// Start building touch events

const API_URL = process.env.REACT_APP_API;

export default function Maps() {
  const [tripId, setTripId] = useState(null);
  const [photoId, setPhotoId] = useState(0);
  const [displayOption, setDisplayOption] = useState("gallery");
  const [openGpxDialog, setOpenGpxDialog] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [currentTrip, setCurrentTrip] = useState(null);
  const [mapId, setMapId] = useState(0);
  const [filterOption, setFilterOption] = useState("Dolomites");
  const [trips, setTrips] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false); //for full screen
  const [touchInfo, setTouchInfo] = useState({
    startX: 0,
    startY: 0,
    endX: 0,
    endY: 0,
  });
  const [pageSize, setPageSize] = useState(20);
  const [showManyTracks, setShowManyTracks] = useState(false);
  const [highPagination, setHighPagination] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const refPage = useRef(1);

  const ref = useRef(null);

  const divRef = useRef(null); //for full screen
  const handleTap = () => {
    if (!isFullscreen) {
      if (divRef.current.requestFullscreen) {
        divRef.current.requestFullscreen();
      } else if (divRef.current.mozRequestFullScreen) {
        // Firefox
        divRef.current.mozRequestFullScreen();
      } else if (divRef.current.webkitRequestFullscreen) {
        // Chrome, Safari, and Opera
        divRef.current.webkitRequestFullscreen();
      } else if (divRef.current.msRequestFullscreen) {
        // IE/Edge
        divRef.current.msRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // Chrome, Safari, and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // IE/Edge
        document.msExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  const [scrollTop, setScrollTop] = useState(0);

  const initPolygon = {
    polyline: [
      {
        lat: 45.931355925276875,
        lng: 10.892878202721477,
      },
    ],
  };

  // useEffect prevents from unwanted repetitions of readCards function with the same page
  useEffect(() => {
    readCards();
    // const polygon = initPolygon;
  }, [refPage.current]);

  useEffect(() => {
    if (tripId === null) return;
    setPhotoId(0);
    setCurrentTrip(trips.find((trip) => trip.id === tripId));
  }, [tripId]);

  useEffect(() => {
    if (tripId === null) return;
    refPage.current = 1; //added
    setTrips([]); //added
    readCards();
    setPhotoId(0);
    setMapId(0);
    setCurrentTrip(trips.find((trip) => trip.id === tripId));
    setDisplayOption("gallery");
  }, [filterOption, pageSize]);

  useEffect(() => {
    if (highPagination) {
      setPageSize(200);
    } else {
      setPageSize(20);
    }
  }, [highPagination]);

  const readCards = async () => {
    setIsLoading(true);
    // console.log(isLoading);
    setError(null);

    const filtrationString =
      filterOption === "All" ? "" : "&filters[trackLink][$eq]=" + filterOption;
    const string =
      "/api/cards?populate=*&sort=tripDate" +
      filtrationString +
      `&pagination[page]=${refPage.current}&pagination[pageSize]=${pageSize}`;
    // console.log("filtrationString", string, isLoading);

    await api
      .get(string)
      .then((res) => {
        const data = res.data.data;
        setTrips((prevItems) => [...prevItems, ...data]);
        setTripId(data[0].id);
        setIsLoading(false);
        console.log("pageSize from readCards = ", pageSize);
      })
      .catch((error) => {
        setError(error);
      })
      .finally();
  };

  const handleScroll = (event) => {
    setScrollTop(event.currentTarget.scrollTop);

    if (
      scrollTop + ref.current.offsetHeight < 0.95 * ref.current.scrollHeight ||
      isLoading
    )
      return;

    setIsLoading(true);
    // readCards();
    refPage.current += 1;
  };

  if (!currentTrip) return "loading...";

  const polygon =
    mapId === 0
      ? initPolygon
      : trips.filter((card) => card.id === mapId)[0].attributes.polygon;

  // console.log("polygon", polygon);
  console.log(trips);
  // console.log(mapId);

  // console.log(trips[0].attributes.gpxtrack.data[0].attributes.url);

  const gpxFileUrl =
    API_URL +
    // "http://localhost:1337" +
    currentTrip.attributes.gpxtrack.data[0].attributes.url;
  const { tripInfo, cardTitle, difficulty, distance, heightGain, startPoint } =
    currentTrip.attributes;
  const tripGallery = currentTrip.attributes.gallery.data;
  // console.log(tripGallery[0].attributes.name);

  tripGallery.sort((a, b) => {
    const nameA = a.attributes.name;
    const nameB = b.attributes.name;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    setTouchInfo((prevState) => ({
      ...prevState,
      startX: touch.clientX,
      startY: touch.clientY,
    }));
  };

  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    setTouchInfo((prevState) => ({
      ...prevState,
      endX: touch.clientX,
      endY: touch.clientY,
    }));
  };

  const handleTouchEnd = () => {
    const diff = touchInfo.endX - touchInfo.startX;
    console.log("Touch diff:", diff);
    if (diff < -50) {
      nextPhoto();
    }
    if (diff > 50) {
      if (photoId > 0) {
        prevPhoto();
      }
    }
  };

  const nextPhoto = () => {
    if (photoId < tripGallery.length - 1) {
      setPhotoId(photoId + 1);
      setImgLoaded(true);
    }
  };

  const prevPhoto = () => {
    if (photoId > 0) {
      setPhotoId(photoId - 1);
      setImgLoaded(false);
    }
  };

  return (
    <div className="responsiveOuterBox">
      <div
        className="responsiveCardBox"
        id="box"
        ref={ref}
        onScroll={handleScroll}
      >
        {trips.map((item) => {
          const attributes = item.attributes;

          // const ImageUrl = "http://localhost:1337" + attributes.cardCover.data.attributes.formats.large.url

          return (
            <div key={item.id}>
              <CardModuleMulti
                cardTitle={attributes.cardTitle}
                tripDate={attributes.tripDate}
                cardCover={attributes.cardCover}
                difficulty={attributes.difficulty}
                distance={attributes.distance}
                heightGain={attributes.heightGain}
                trackLink={attributes.trackLink}
                imageUrl={
                  API_URL +
                  // "http://localhost:1337" +
                  attributes.cardCover.data.attributes.formats.medium.url
                }
                setCardId={setTripId}
                cardId={item.id}
                setPhotoId={setPhotoId}
                setDisplayOption={setDisplayOption}
                displayOption={displayOption}
                setMapId={setMapId}
                tripInfo={attributes.tripInfo}
                startPoint={attributes.startPoint}
              />
            </div>
          );
        })}
      </div>

      <div
        className="responsiveSideBox"
        style={{
          display: "flex",
          // flexwrap: "wrap",
          flexDirection: "column",
          // backgroundColor: "#1976d2",
          width: "80%",
          justifyContent: "space-around",
          overflow: "hidden",
          marginRight: "30px",
          marginLeft: "30px",
          flexGrow: "1",
        }}
      >
        <div
          style={{
            minHeight: "40px",
          }}
        >
          <Typography variant="h5" color="white" sx={{ mt: 1 }}>
            {cardTitle}
            {/* {highPagination.toString()} */}
            {/* {pageSize.toString()} */}
          </Typography>
        </div>

        <div
          className="mapBox"
          style={{
            display: displayOption === "map" ? "flex" : "none",
            flexGrow: "1",
            backgroundColor: "red",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <MapModuleForMaps
            filterOption={filterOption}
            polygon={polygon}
            cardItemsList={trips}
            displayOption={displayOption}
            setTripId={setTripId}
            setMapId={setMapId}
            showManyTracks={showManyTracks}
          />
        </div>

        <div
          style={{
            display: displayOption === "gallery" ? "flex" : "none",
            flexDirection: "column",
            flexGrow: "1",
          }}
        >
          <div
            className="newGalleryContainer"
            ref={divRef}
            onClick={handleTap}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            style={{
              backgroundImage:
                'url("' +
                API_URL +
                // "http://localhost:1337" +
                tripGallery[photoId].attributes.formats.xlarge.url +
                '")',
              flexGrow: "1",
            }}
          ></div>

          {imgLoaded && photoId < tripGallery.length - 1 && (
            <img
              className="preload-img"
              src={
                API_URL +
                // "http://localhost:1337" +
                tripGallery[photoId + 1].attributes.formats.xlarge.url
              }
              alt="Italian Trulli"
            ></img>
          )}
          <div
            className="arrowsBox"
            style={{
              display: "flex",
              flexwrap: "wrap",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: "2px",
            }}
          >
            <Button
              size="large"
              variant="outlined"
              startIcon={<ArrowBackIosIcon />}
              sx={{ color: "white" }}
              onClick={() => {
                prevPhoto();
              }}
              disabled={photoId < 1}
            >
              BACK
            </Button>

            <Button
              size="large"
              variant="outlined"
              // endIcon={<ArrowForwardIosIcon />}
              sx={{
                color: "gray",
                // borderColor: "white",
              }}
            >
              {photoId + 1} / {tripGallery.length}
            </Button>

            <Button
              size="large"
              variant="outlined"
              endIcon={<ArrowForwardIosIcon />}
              sx={{
                color: "white",
                // borderColor: "white",
              }}
              onClick={() => {
                nextPhoto();
              }}
              disabled={photoId >= tripGallery.length - 1}
            >
              NEXT
            </Button>
          </div>
        </div>

        <Buttons
          setDisplayOption={setDisplayOption}
          setOpenGpxDialog={setOpenGpxDialog}
          setOpenInfoDialog={setOpenInfoDialog}
          setOpenFilterDialog={setOpenFilterDialog}
          setFilterOption={setFilterOption}
          filterOption={filterOption}
          cardId={tripId}
          setMapId={setMapId}
        />

        {openGpxDialog && (
          <CustomizedDialogs
            openGpxDialog={openGpxDialog}
            setOpenGpxDialog={setOpenGpxDialog}
            gpxFileUrl={gpxFileUrl}
            cardTitle={cardTitle}
          />
        )}

        {openInfoDialog && (
          <CustomizedDialogsInfo
            openInfoDialog={openInfoDialog}
            setOpenInfoDialog={setOpenInfoDialog}
            tripInfo={tripInfo}
            cardTitle={cardTitle}
            difficulty={difficulty}
            distance={distance}
            heightGain={heightGain}
            startPoint={startPoint}
            region={currentTrip.attributes.trackLink}
            tripDate={currentTrip.attributes.tripDate}
          />
        )}

        {openFilterDialog && (
          <CustomizedDialogsFilter
            openFilterDialog={openFilterDialog}
            setOpenFilterDialog={setOpenFilterDialog}
            setFilterOption={setFilterOption}
            filterOption={filterOption}
            setShowManyTracks={setShowManyTracks}
            showManyTracks={showManyTracks}
            highPagination={highPagination}
            setHighPagination={setHighPagination}
          />
        )}
      </div>
    </div>
  );
}
