import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useState, useEffect, useRef } from "react";
import api from "../api.js";

export default function RadioButtonsGroup({
  chosenButton,
  setChosenButton,
  // uniqueTrackLinks,
}) {
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    regionsFromServer();
  }, []);

  const regionsFromServer = async () => {
    let res = await api
      .get("/api/location")
      .then((res) => {
        const regions = res.data.data.attributes.regions.regions;
        setRegions(regions);
        // console.log(regions);
        // console.log(res.data.data.attributes.regions.regions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChangeSelection = (event) => {
    setChosenButton(event.target.value);
    // console.log(uniqueTrackLinks);
  };

  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">
        Choose region to explore
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="All"
        name="radio-buttons-group"
        onChange={onChangeSelection}
        value={chosenButton}
      >
        <FormControlLabel value="All" control={<Radio />} label="All" />

        {regions.map((trackLink, idx) => (
          <FormControlLabel
            key={`${idx}`}
            value={`${regions[idx]}`}
            control={<Radio />}
            label={`${regions[idx]}`}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
