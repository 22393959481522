// renderArrows.js

import { Polyline } from "@react-google-maps/api";

const renderArrows = (path, zoomLevel) => {
  const arrows = [];
  if (zoomLevel > 12) {
    // Set your desired zoom level to show arrows
    for (let i = 10; i < path.length; i += 30) {
      arrows.push(
        <Polyline
          key={i}
          path={[path[i - 1], path[i]]}
          options={{
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2,
            icons: [
              {
                icon: {
                  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  scale: 2,
                  strokeColor: "#0000FF",
                },
                offset: "100%",
              },
            ],
          }}
        />
      );
    }
  }
  return arrows;
};

export default renderArrows;
