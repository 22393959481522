export const polylineOptions = {
  strokeColor: "#FF0000",
  strokeOpacity: 0.8,
  strokeWeight: 1,
  fillColor: "#FF0000",
  fillOpacity: 0.35,
  clickable: true,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
};

// Enlarged clickable area without changing the visual appearance

export const getHitAreaOptions = (polygon) => ({
  strokeColor: "transparent",
  strokeOpacity: 0.0,
  strokeWeight: 20,
  clickable: true,
  draggable: false,
  editable: false,
  visible: true,
  paths: polygon.polyline,
  zIndex: 2,
});
